import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../zz_general/utils/configs/firebase';
import { useRecoilState, useRecoilValue, selector } from 'recoil';
import { firstViewState, bannerState } from '../../../../lp-legacy/atoms';
import { adminState } from '../../../../management-beta/store/atom';
import css from '../../index.module.scss';
import { scrollLock, clearScrollLock } from '../../utils';
import { CountdownTimer } from '../../../../zz_general/components/atoms/contDown';
import dayjs from 'dayjs';

export const FirstView = (props) => {
  // #region variables
  const navigate = useNavigate();
  const banner = useRecoilValue(bannerState);
  const admin = useRecoilValue(adminState);
  const [firstView, setFirstView] = useRecoilState(firstViewState);
  const expiryDate = dayjs('2024-11-20').format('YYYY-MM-DD:HH:mm:ss');

  // #endregion
  // #region functions
  const setMaintenance = () => {
    const promise = getMaintenance();
    promise
      .then((response) => {
        if (response.maintenance === true) {
          navigate('/maintenance');
        }
      })
      .catch((_error) => {
        // console.log(error);
      });
  };

  const getMaintenance = async () => {
    const docRef = doc(db, 'LP', process.env.REACT_APP_IS_PROD === 'true' ? 'LP' : 'LP-test');
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  };

  // #endregion
  // #region useEffect
  useEffect(() => {
    if (!admin.testPurchase) {
      setMaintenance();
    }
  }, []);

  useEffect(() => {
    document.getElementById('bannerPc').addEventListener('click', () => props.swipeToSpecificSlide(10));
    document.getElementById('bannerSp').addEventListener('click', () => props.swipeToSpecificSlide(10));
  }, []);

  // #endregion
  // #region return
  return (
    <>
      <div id="firstView" className={classNames(css.firstView)}>
        <div
          className={classNames(
            css.fvBanner,
            firstView['visited'] ? '' : css.Animation1_2,
            banner.shopClicked ? '' : css.zIndex10
          )}
        >
          <div>
            {/* <div className={css.countDownTimer}>
              販売終了まで
              <CountdownTimer targetDate={expiryDate} />
            </div> */}
            <img
              // MEMO: 今後復活のために残す
              // src={`${process.env.PUBLIC_URL}/top/whiteBlack_tiramisu_pc.webp`}
              // src={`${process.env.PUBLIC_URL}/top/matcha_banner_pc.webp`}
              // src={`${process.env.PUBLIC_URL}/top/bun_banner_pc.webp`}
              // src={`${process.env.PUBLIC_URL}/top/namachoco_banner_pc.webp`}
              src={`${process.env.PUBLIC_URL}/top/quiz_banner_pc.webp`}
              // src={`${process.env.PUBLIC_URL}/top/kamiwaza-banner-pc.webp`}
              // onClick={props.scrollToFeatured}
              // onClick={() => window.open('/kamaitachi-no-okite', 'blank_')}
              onClick={() => window.open('/quiz', 'blank_')}
              id="bannerPc"
            />
          </div>
        </div>
        <div
          className={classNames(
            css.fvBannerSp,
            firstView['visited'] ? '' : css.Animation1_2,
            banner.shopClicked ? '' : css.zIndex10
          )}
        >
          <div>
            {/* <div className={css.countDownTimer}>
              販売終了まで
              <CountdownTimer targetDate={expiryDate} />
            </div> */}
            <img
              // MEMO: 今後復活のために残す
              // src={`${process.env.PUBLIC_URL}/top/whiteBlack_tiramisu_sp.webp`}
              // src={`${process.env.PUBLIC_URL}/top/matcha_banner_sp.webp`}
              // src={`${process.env.PUBLIC_URL}/top/bun_banner_sp.webp`}
              // src={`${process.env.PUBLIC_URL}/top/namachoco_banner_sp.webp`}
              src={`${process.env.PUBLIC_URL}/top/quiz_banner_sp.webp`}
              // src={`${process.env.PUBLIC_URL}/top/kamiwaza-banner-sp.webp`}
              // onClick={props.scrollToFeatured}
              // onClick={() => window.open('/kamaitachi-no-okite', 'blank_')}
              onClick={() => window.open('/quiz', 'blank_')}
              id="bannerSp"
            />
          </div>
        </div>
        <div
          className={firstView['visited'] ? '' : css.animation_end}
          onAnimationStart={() => {
            scrollLock(document.getElementById('scrollLock'));
          }}
          onAnimationEnd={() => {
            clearScrollLock();
            setFirstView((current) => {
              const future = JSON.parse(JSON.stringify(current));
              future['visited'] = true;
              return future;
            });
            window.addEventListener('scroll', props.handleScroll);
          }}
        >
          <div id="scrollLock"></div>
        </div>
        <div
          className={firstView['visited'] ? '' : css.encourage_scrolling}
          // onAnimationEnd={() => animationEnd()}
        ></div>
        <div className={classNames(css.Img1, firstView['visited'] ? '' : css.Animation1)}></div>
        <div className={classNames(!admin.testPurchase ? css.Img1 : css.displayNone)}>
          <picture>
            <source srcSet={`${process.env.PUBLIC_URL}/top/BENEREGALO_logo1.avif`} type="image/avif" />
            <img
              src={`${process.env.PUBLIC_URL}/top/BENEREGALO_logo1.webp`}
              alt=""
              className={classNames(css.logoImage, firstView['visited'] ? '' : css.Animation1)}
            />
          </picture>
          <img
            src={`${process.env.PUBLIC_URL}/top/BENEREGALO_logo2.webp`}
            alt=""
            className={classNames(css.logoText, firstView['visited'] ? '' : css.Animation1_2)}
          />
        </div>
        <div className={classNames(admin.testPurchase ? css.testPurchase : css.displayNone)}>テスト購入</div>
        <picture>
          <source srcSet={`${process.env.PUBLIC_URL}/top/FV_sp.avif`} type="image/avif" />
          <img
            src={`${process.env.PUBLIC_URL}/top/FV_sp.webp`}
            alt=""
            className={classNames(css.backImageSp, firstView['visited'] ? '' : css.Animation0)}
          />
        </picture>
        <picture>
          <source srcSet={`${process.env.PUBLIC_URL}/top/FV_pc.avif`} type="image/avif" />
          <img
            src={`${process.env.PUBLIC_URL}/top/FV_pc.webp`}
            alt=""
            className={classNames(css.backImagePc, firstView['visited'] ? '' : css.Animation0)}
          />
        </picture>
        <img
          src={`${process.env.PUBLIC_URL}/scroll_down.svg`}
          alt=""
          className={classNames(css.scrollDownImg, firstView['visited'] ? css.displayNone : css.Animation1_2)}
        />
      </div>
    </>
  );
};
